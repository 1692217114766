@import 'settings/variable'; //themes' colors

//vendor...
@import './vendor.scss';

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span


//components...
@import 'component/btn';
@import './component/card';
@import './component/check-box';
@import './component/sidebar';
@import './component/topbar';
@import './component/load';
@import './component/form-box';
@import './component/form';
@import './component/material-form';
@import './component/edit-table';
@import './component/select-options';
@import './component/pagination';
@import './component/table.scss';
@import './component/dropdown.scss';
@import './component/text-editor.scss';
@import './component/date-picker.scss';
@import './component/dropzone.scss';
@import './component/panel.scss';
@import './component/tabs.scss';
@import './component/time-picker';
@import './component/material-table.scss';
@import './component/tooltip.scss';
@import './component/radio-btn.scss';
@import './component/color-picker.scss';
@import './component/wizard.scss';
@import './component/switch.scss';
@import './component/slider.scss';
@import './component/badge.scss';
@import './component/app-preview-html';

//containers...
@import './containers/help.scss';
@import './containers/products-list.scss';
@import './containers/dashboard.scss';
@import './containers/matches.scss';
@import './containers/affiliations.scss';
@import './containers/lineup.scss';
@import './containers/app_layout.scss';
@import './containers/question.scss';

//pages...
@import 'containers/account';
@import 'containers/boxoffice.scss';
@import 'containers/kiosk.scss';
@import 'containers/packages.scss';
@import 'containers/notfound.scss';

//objects...
@import 'objects/layout.scss';

//custom...
@import './custom.scss';

@import './Linearicons.css';
