.kiosk {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #e7e8ee;

  .kiosk-navbar {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    background-color: #286ea0;
    min-height: 60px;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    .back-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background: transparent;
      border: none;
      border-right: 1px solid #d8d8d8;
    }
    .back-symbol {
      width: 10px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      padding: 4px;
      transform: rotate(135deg);
    }

    .kiosk-navbar-title {
      margin-left: 20px;
      color: white;
      margin: 0;
      margin-left: 20px;
      font-weight: bolder;
      font-size: 18px;
    }
    .navbar-refresh {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background-color: transparent;
      outline: none;
      border: none;
      border-left: 1px solid #d8d8d8;
    }

    .navbar-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background-color: transparent;
      outline: none;
      border: none;
      border-left: 1px solid #d8d8d8;
      padding: 0 20px;

      .icon-margin {
        margin-right: 5px;
      }

      p {
        color: white;
        margin: 0;
        font-size: 14px;
      }

      .navbar-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .kiosk-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    box-shadow: 0 3px 8px 0 rgba(159, 159, 159, 0.35);
    background-color: white;

    .kiosk-list-header {
      padding: 20px;
      border-bottom: 2px solid #dddddd;
    }

    .kiosk-list {
      overflow: scroll;
    }

    button.kiosk-list-item:hover {
      background-color: #dddddd;
      border-bottom: 1px solid #dddddd;
    }

    .kiosk-list-item.active {
      background-color: #cccccc;
      border-bottom: 1px solid #cccccc;
    }

    .kiosk-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;
      height: 85px;
      background-color: transparent;
      border-bottom: 1px solid #e0e0e6;
      padding: 0 20px;

      .kiosk-list-item-left {
        display: flex;
      }

      .kiosk-list-item-image {
        width: 65px;
        height: 65px;
      }

      .kiosk-list-item-state {
        display: flex;
        align-items: center;

        .kiosk-list-item-circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .kiosk-list-item-information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 10px;
        h3 {
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }

  .kiosk-type-container {
    display: flex;
    flex: 1;
    padding: 15px;
    overflow: hidden;

    .kiosk-type-item.middle {
      margin: 0 15px;
    }
    .kiosk-type-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: white;
      overflow-y: scroll;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

      .kiosk-type-item-header {
        display: flex;
        align-items: center;
        min-height: 80px;
        padding: 0 20px;
      }

      .kiosk-type-item-filter {
        display: flex;
        align-items: center;
        height: 30px;
        background-color: #dddddd;

        label {
          display: flex;
          align-items: center;
          height: 30px;
          margin: 0;
          input {
            width: 20px;
            height: 20px;
            margin: 0 10px;
            outline: none;
          }

          p {
            margin: 0;
          }
        }
      }

      .kiosk-type-item-body {
        height: 100%;
        padding: 15px;

        .kiosk-box.active {
          background-color: #e23d51;
          h4 {
            color: white;
          }
          p {
            color: white;
          }
        }
        .kiosk-box.processing {
          background-color: #fff153;
          h4 {
            color: #525252;
          }
          p {
            color: #726f54;
          }
        }
        .kiosk-box.ready {
          background-color: #71d35b;
          h4 {
            color: white;
          }
          p {
            color: white;
          }
        }

        .kiosk-box:hover {
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
        }

        .kiosk-box {
          display: flex;
          flex-direction: column;
          padding: 15px 15px 0 0 0;
          margin-bottom: 10px;
          border-radius: 5px;
          cursor: pointer;
          transition-duration: 0.2s;

          p {
            color: #646777;
            font-size: 14px;
          }

          .kiosk-box-body.hover {
            height: 150px;
          }

          .kiosk-box-body {
            display: flex;
            justify-content: space-between;
            height: 100%;
            padding: 15px;

            .kiosk-box-body-tag {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              p.push {
                text-decoration: underline;
                margin-bottom: 3px;
              }

              h4 {
                font-size: 22px;
                font-weight: bold;
                text-align: center;
              }
              p {
                font-size: 12px;
              }
            }
          }

          .kiosk-box-button {
            flex: 1;
            border: none;
            height: 40px;
          }

          .processing.kiosk-box-button {
            background-color: #e0c624;
            color: #fff153;
            font-size: 15px;
            font-weight: bold;
          }

          .processing.kiosk-box-button:last-child {
            background-color: #c7a715;
          }

          .ready.kiosk-box-button {
            background-color: #36b857;
            color: #71d35b;
            font-size: 15px;
            font-weight: bold;
          }
          .ready.kiosk-box-button:nth-child(even) {
            background-color: #28a660;
          }

          .kiosk-box-buton-single {
            float: right;
            outline: none;
            border: none;
            background-color: #920e0e;
            color: #e23d51;
            width: 180px;
            height: 40px;
            font-size: 15px;
            font-weight: bold;
            border-radius: 5px 0;
          }
        }
      }
    }
  }

  .auction {
    display: flex;
    height: 100%;
    overflow-x: hidden;
    padding: 15px;

    table {
      overflow-x: scroll;
      width: 100%;
      thead {
        border-bottom: 1px solid #dddddd;
        th {
          font-size: 14px;
          padding: 8px;
        }
      }
      tbody {
        td:first-child {
          margin-left: 8px;
        }
        td {
          font-size: 14px;
          padding: 8px;
          border-bottom: 1px solid #dddddd;
        }
      }
    }

    button {
      font-size: 13px;
      padding: 8px 12px;
      font-weight: bold;
      outline: none;
      border: none;
      border-radius: 5px;
      white-space: nowrap;
    }

    button.primary {
      color: #ffffff;
      background-color: #2096f3;
      word-wrap: none;
    }

    button.danger {
      color: #ffffff;
      background-color: #d9534f;
    }

    .list-auctions {
      flex: 1;
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      overflow-y: scroll;

      .list-auctions-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        height: 80px;
        border-bottom: 10px solid #286ea0;
      }

      .list-auctions-body {
        .list-auctions-item {
          cursor: move;
        }
      }
    }

    .list-bids {
      flex: 2;
      background-color: white;
      margin-left: 15px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      overflow-y: scroll;

      .list-bids-header {
        display: flex;
        align-items: center;
        padding: 20px;
        height: 80px;
        border-bottom: 10px solid #286ea0;
      }
    }
  }
}

.modal-dialog.kiosk-modal {
  max-width: none;
  width: 1024px;

  .kiosk-modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c3c3c3;
    padding: 10px 0;
    button {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  .kiosk-modal-body {
    padding: 10px 0 0 0;

    .list-refund {
      button {
        background-color: #286ea0;
        color: white;
        border: none;
        outline: none;
        padding: 10px 12px;
        border-radius: 5px;
      }
    }
  }
}

.modal-dialog.history-modal {
  .history-modal-body-search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 10px;
    button {
      color: white;
      background-color: #337ab7;
      border: none;
      border-radius: 5px;
      outline: none;
      height: 34px;
      margin-left: 10px;
    }
    label {
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-weight: bold;
      }
      input {
        margin: 0 10px;
        margin-bottom: 0;
        outline: none;
        border: 1px solid #c3c3c3;
        border-radius: 5px;
        height: 34px;
        width: 250px;
        padding: 6px 12px;
      }
    }
  }
  .history-modal-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .list-item {
      display: flex;
      border-bottom: 1px solid #c3c3c3;
      min-height: 100px;
      padding: 10px 0;

      .list-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        .list-header {
          display: flex;
          align-items: center;
          span {
            background-color: #d0d0d0;
            color: #868686;
            border-radius: 5px;
            letter-spacing: 0.5px;
            margin-left: 10px;
            padding: 3px 8px;
            font-weight: bold;
          }
          span.ready {
            color: #4f7624;
            background-color: #95dd46;
          }

          span.processing {
            color: #907d02;
            background-color: #f1d000;
          }

          span.inactive {
            color: #80232e;
            background-color: #e23d51;
          }
        }

        .list-body {
          display: flex;
          justify-content: space-between;

          .list-refund {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            button {
              color: white;
              background-color: #337ab7;
              border: none;
              border-radius: 5px;
              outline: none;
              height: 34px;
              margin-left: 10px;
            }
          }

          .list-column {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            span {
              font-size: 14px;
            }
            ul {
              font-size: 14px;
              margin-left: 20px;
            }
          }
          .list-row {
            display: flex;
          }
        }
      }
    }
  }
  .disabled {
    .pagination__link-icon {
      fill: #dddddd;
    }
  }
  .active {
    .pagination__link {
      color: unset;
    }
  }
  .pagination__link:hover {
    color: #337ab7;
  }
  .pagination__link--arrow {
    background-color: #f2f4f7;
  }
  .pagination__link--arrow:hover {
    background-color: #337ab7;
  }
}

.modal-dialog.products-modal {
  .kiosk-modal-modal-info {
    background: #eeeeee;
    padding: 4px 16px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .products-modal-category.placeholder {
    height: unset;
    div.products-modal-category-header {
      display: flex;
      height: 50px;
    }
    div {
      display: none;
    }
  }
  .products-modal-category {
    border: 2px solid #286ea0;
    border-radius: 5px;
    margin-bottom: 10px;
    .products-modal-category-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: move;
      padding: 10px;
      height: 50px;
      background-color: #286ea0;

      h4 {
        color: white;
        font-weight: bold;
      }

      button.upwards {
        svg {
          transform: rotate(180deg);
        }
      }

      button {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    .products-modal-category-body.hide {
      display: none;
    }

    .products-modal-category-body {
      transition-delay: 0.3s;
      margin: 15px;
      border: 1px solid #dddddd;
      border-bottom: 0;
      border-radius: 5px;

      .product-item:only-child {
        .product-item-state {
          border-radius: 5px 4px 4px 5px !important;
        }
      }

      .product-item:first-child {
        .product-item-state {
          border-radius: 5px 4px 0px 5px;
        }
      }
      .product-item:last-child {
        border-radius: 5px;
        .product-item-state {
          border-radius: 5px 0 5px 5px;
        }
      }
      .product-item {
        cursor: move;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #dddddd;
        padding: 0 0 0 10px;

        .product-item-state {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 100%;
          border: none;
          border-radius: 5px 0 0 5px;
          background-color: transparent;
          outline: none;
          color: white;
        }
      }
    }
  }
}

.kiosk-box-placeholder.active {
  border-color: #e23d51;
}

.flex-center {
  display: flex;
  flex: 1;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.placeholder {
  width: 100%;
  height: 75px;
  background-color: #a8d1ee;
}

.print-order {
  display: none;
}

@media print {
  .print-order {
    display: flex;
    flex-direction: column;
    color: black;
    width: 950px;

    &-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border-top: 1px solid #cacaca;
      padding: 30px;
    }

    &-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-name {
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
    }

    &-identifier {
      font-weight: bold;
      font-size: 90px;
      line-height: 90px;
    }

    p {
      font-size: 24px;
      margin: 0;
    }
  }

  .kiosk,
  .Help-btn {
    display: none;
  }
}
