@media screen {
	.navbar-modal-ticket {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		border-bottom: 1px solid #DEE2E6;

		&:nth-last-child(1) {
			border-bottom: unset;
		}

		p {
			font-size: 16px;
			margin: 0;
		}

		button {
			color: white;
			background-color: #286EA0;
			border: none;
			width: 120px;
			height: 40px;
			font-size: 16px;
			margin-left: 10px;
		}
	}

	.navbar-modal-header h5 {
		display: flex;
    width: 100%;
    justify-content: space-between;
		align-items: center;

		input {
			min-width: 200px;
		}
	}

	.navbar-modal-content {
		max-height: 75vh;
		overflow: auto;
	}

	.box-office {
		min-width: 500px;
		width: 100vw;
		height: 100vh;
		background-color: #E7E8EE;
		font-family: Roboto;
		display: flex;
		flex-direction: column;

		.navbar {
			display: flex;
			flex-direction: row;
			height: 60px;
			background-color: #286EA0;
			margin: 0;
			padding: 0;

			.navbar-left {
				display: flex;
				height: 100%;
			}

			.back-symbol {
				width: 10px;
				height: 10px;
				border: solid white;
				border-width: 0 2px 2px 0;
				padding: 4px;
				transform: rotate(135deg);
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 60px;
				height: 60px;
				background-color: transparent;
				border: 0;
				border-right: 1px solid #D8D8D8;
				cursor: pointer;
			}
			.navbar-match-select {
				width: 300px;
				height: 100%;

				.Select-control {
					background-color: #4582B0;
					height: 100%;
					border: none;
					border-radius: 0;
					text-align: center;

					.Select-arrow {
						border-color: white transparent transparent;
					}

					.Select-input {
						display: none !important;
					}

					.Select-value {
						display: flex;
						justify-content: center;
						align-items: center;
						color: white;
						font-size: 18px;
						span.Select-value-label {
							color: white !important;
						}
					}

					.Select-placeholder {
						display: flex;
						justify-content: center;
						align-items: center;
						color: white;
						font-size: 18px;
					}

					.Select-multi-value-wrapper {
						height: 100%;
					}
				}
			}

			.navbar-purchase-history {
				color: white;
				width: 175px;
				border-right: none;
				border-left: 1px solid #D8D8D8;
				font-size: 16px;
			}
		}

		.match-selector {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 150px;
				background-color: white;
				box-shadow: 0 3px 8px 0 rgba(159, 159, 159, 0.35);
				padding: 0 60px 0 60px;

				.file-icon {
					width: 24px;
					height: 30px;
					cursor: pointer;
				}

				.new-customer {
					display: flex;
					flex-direction: column;
					justify-content: center;
					background-color: transparent;
					border: 0;

					.horizontal-center {
						display: flex;
						width: 100%;
						justify-content: center;
					}
				}

				p {
					font-size: 12px;
					font-weight: bold;
					color: #989898;
				}

				@media screen and (max-width: 1000px) and (min-width: 700px) {
					div select {
						font-size: 24px;
					}
				}


				@media screen and (max-width: 700px) and (min-width: 500px) {
					div select {
						font-size: 16px;
					}
				}

				@media screen and (max-width: 500px) and (min-width: 0) {
					div select {
						font-size: 12px;
					}
				}

				select {
					margin-left: -8px;
					background-color: white;
					border: 0;
					outline: 0;
					font-size: 30px;
					font-weight: bolder;
					color: #434954;
				}
		}

		.order-overview {
				display: flex;
				min-height: 120px;
				height: auto;
				background-color: white;
				border-top: 1px solid #A6A6A6;

				button.column {
					cursor: pointer;
					background-color: #236da2;
					border: 0;

					img {
						filter: brightness(2);
						width: 33px;
						height: 30px;
					}
					p {
						color: white;
					}
				}

				button.column:disabled {
					background-color: #E6E6E6;
					border: 0;
					cursor: not-allowed;

					img {
						filter: none;
						width: 33px;
						height: 30px;
					}

					p {
						color: #989898;
					}
				}
				.column.column-qr {
					display: flex;
					justify-content: flex-end;
					align-items: flex-start;
					p {
						margin-left: 5px;
						margin-top: 0;
					}
					input.checkbox {
						margin-top: 1px;
					}
				}
				.column {
					flex: 1;
					padding: 14px;

					h1 {
						color: #286EA0;
						font-size: 32px;
						font-weight: bold;
					}

					.bold {
						font-weight: bolder;
					}

					.italic.danger {
						font-style: italic;
						color: red;
					}

					.row {
						margin: 0;
						height: 32px;
					}

					p {
						font-size: 14px;
						color: #434343;
						font-weight: bold;
						letter-spacing: 1px;
					}

					h2 {
						font-size: 20px;
						color: #434343;
					}
				}
		}

		.ticket-selector {
				display: flex;
				flex: 1;
				padding: 15px;
				flex-flow: wrap;
				overflow-y: hidden;

				@media only screen and (max-width: 1150px) and (min-width: 800px) {
					div.card {
						height: calc(50% - 20px);
						min-width: 360px;
					}
				}

				@media only screen and (max-width: 800px) and (min-width: 600px) {
					div.card {
						height: calc(50% - 20px);
						min-width: 250px;
					}
				}

				@media only screen and (min-width: 0px) and (max-width: 600px) {
					div.card {
						height: calc(25% - 20px);
						min-width: 300px;
					}
				}

				.card {
					flex: 1;
					height: calc(100% - 20px);
					border-radius: 0;
					margin: 10px;
					background-color: white;
					box-shadow: 0 3px 8px 0 rgba(159, 159, 159, 0.35);
					padding-bottom: 0;

					.scroll {
						overflow-y: scroll;
					}

					.header {
						display: flex;
						align-items: center;
						height: 65px;
						min-height: 65px;
						padding: 20px;

						h1 {
							font-weight: bolder;
							font-size: 18px;
						}

						.ticket-selector-header-checkbox {
							display: flex;
							justify-content: flex-end;
							flex: 1;
							align-self: center;

							p {
								margin-right: 5px;
							}
						}
					}

					.selector {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 60px;
						padding: 0 20px 0 20px;
						border-top: 1px solid #EEEEEE;
						width: 100%;

						&:hover {
							background-color: #CDDBE8;
						}

						p {
							font-size: 16px;
							color: #757575;
						}
					}

					.selector:disabled {
						background-color: #ececec;
						cursor: default;

						p {
							color: #d0d0d0;
						}
					}

					.selector.selected {
						background-color: rgba(36, 144, 218, 0.25);
					}

					.amount-selector {
						.center {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;

							.amount {
								margin: 0;
								margin-left: 8px;
								margin-right: 8px;
								font-size: 32px;
								color: #757575;
								height: 48px;
							}
						}

						img {
							width: 42px;
							height: 42px;
							pointer-events: none;
						}
					}

					.field-selector {
						padding: 0;
						padding-left: 20px;
						padding-right: 20px;

						.field-color {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 59px;
							height: 59px;
							background-color: #195681;

							p {
								color: white;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}
					}
					.seat-selector {
						.circle {
							width: 42px;
							height: 42px;
							border: 1px solid #757575;
							border-radius: 50%;
						}

						.checkbox {
							width: 42px;
							height: 42px;
							border: none;
						}
					}
				}
		}
		.no-button-style {
				background-color: transparent;
				border: none;
				cursor: pointer;
		}
	}
}

.textalign-right {
	text-align: right;
}

.printer-box {
	display: none;
}

@media print {
	div.modal {
		display: none;
	}

	.Toastify {
		display: none;
	}

	div.modal-backdrop {
		display: none;
	}
	div.modal-content {
		display: none;
	}
	#_hj_feedback_container {
		display: none;
	}

	div.theme-light .wrapper {
		background-color: white;
	}
	.loading-full {
		display: none !important;
	}


	* {
		margin: 0;
		padding: 0;
	}

	.box-office {
		display: none;
	}

	.printer-container {
		page-break-after: auto;
	}


	.printer-box {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		padding: 12mm 10mm;
		color: black;
		page-break-inside: avoid;
		height: 123mm;
	}

	.printer-box.mini {
		overflow: hidden;
		width: 160.5mm;
		height: 94mm;
		padding: 0;

		@page {
			size:  107mm 63.5mm;
		}

		.printer-box-reference {
			background-color: transparent;
			width: unset;
			margin-left: 3mm;
			p {
				color: black !important;
			}
		}

		.printer-box-tfg-mini {
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			position: absolute;
			right: 0;

			img {
				transform: rotate(270deg);
				height: 5mm;
				width: auto;
			}
		}

		.printer-qr {
			width: 24mm;
			height: 24mm;
		}

		.printer-box-match {
			padding-top: 3mm;
			padding-left: 4mm;
		}

		.printer-box-location-row-seat {
			display: flex;
			margin-bottom: 4mm;
		}

		.printer-box-container {
			border: 0;
			border-top: 5mm solid black;
			border-bottom: 5mm solid black;
		}
		.printer-border {
			display: none;
		}

		.printer-box-left {
			position: relative;
			padding: 4mm 0 2mm 1mm;
			flex: 1;
		}
		

		.printer-pin {
			height: 6mm;
		}

		.printer-box-awayteam-text {
			line-height: 5mm;
		}

		.printer-box-margin {
			margin: 5mm 0;
		}

		.printer-box-right {
			width: 35mm;
			margin-right: 6mm;
			padding: 8mm 0 4mm;
		}
	}
	.printer-box-container {
		display: flex;
		border: 2px solid #363636;
		height: 100%;
		overflow: hidden;
	}
	.printer-box-left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		padding: 5mm 7mm;
	}
	.printer-box-right {
		display: flex;
		flex-direction: column;
		width: 58mm;
		height: 100%;
		padding: 5mm 7mm;
	}
	p {
		color: black !important;
	}

  .printer-title {
    font-family: Roboto, sans-serif;
    font-size: 16pt;
    font-weight: 900;
  }

  .printer-information {
    font-family: Roboto, sans-serif;
		font-size: 13pt;
		font-weight: 500;
  }

  .printer-reference {
    font-size: 10pt;
    font-family: Roboto, sans-serif;
  }

  .line {
    width: 30mm;
    margin-top: 2mm;
    margin-bottom: 2mm;
    border-top: 1px solid black;
  }

  .cut-line {
    width: 100%;
    border-top: 1px dashed black;
  }

  .tickets-index {
    display: none;
	}
	.flex-row {
		display: flex;
	}
	.flex-4 {
		flex: 4
	}

	.self-end {
		align-self: flex-end;
	}
	.textalign-right {
		text-align: right;
	}

	.printer-sponsor {
		width: fit-content;
		height: 18px;
		object-fit: contain;
	}

	.printer-qr {
		width: 24mm;
		height: 24mm;
	}
	.printer-box-hometeam-logo {
		width: 18mm;
		height: 18mm;
	}
	.printer-box-match {
		padding-top: 3mm;
		padding-left: 2mm;
	}
	.printer-pin {
		width: auto;
		height: 5mm;
		margin-right: 2mm;
	}
	.printer-stadium {
		display: flex;
		align-items: center;
	}
	.printer-box-sponsor {
		p {
			margin: 0;
			font-weight: 8pt;
			font-style: italic;
		}
		img {
			width: auto;
			height: 10mm;
		}
	}
	.printer-ticket-info-text {
		margin: 0;
		font-size: 10pt;
		font-weight: 500;
	}
	p.printer-ticket-info-margin {
		margin-left: 5.65mm;
	}
	.printer-box-hometeam-text {
		font-size: 22pt;
		margin: 0;
		font-weight: bold;
	}
	.printer-box-awayteam-text {
		font-size: 16pt;
		margin: 0;
	}
	.printer-box-margin {
		margin: 5mm 0;
	}
	p.printer-box-info-text {
		font-weight: 500;
		font-size: 10pt;
		margin: 0;
		margin-bottom: -1.5mm;
	}
	.printer-box-doors-open-text {
		font-weight: 500;
		font-size: 10pt;
		margin: 0;
		margin-top: -2mm;
	}
	.printer-box-match-start-text {
		font-size: 14pt;
		font-weight: bold;
		margin: 0;
	}
	.printer-box-reference {
		width: 6mm;
		display: flex;
		align-items: center;
		p {
			writing-mode: vertical-rl;
			text-orientation: mixed;
			color: white !important;
			transform: rotate(180deg);
			margin-top: 0.5mm;
		}
		height: 100%;
		color: white;
		background-color: #363636;
		overflow: hidden;
	}
	.printer-border-container {
		margin-top: -4mm;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		height: calc(100% + 8mm);
	}
	.printer-border {
		width: 6mm;
		height: 100%;
		background-color: #363636;
	}
	.printer-border-right .printer-border-circle {
		left: -4mm;
	}
	.printer-border-circle {
		position: relative;
		left: 2mm;
		width: 8mm;
		height: 8mm;
		background-color: #363636;
		border-radius: 50%;
	}

	.printer-box-location {
		p {
			margin: 0;
			font-size: 12pt;
			font-weight: 500;
			&:last-child {
				font-weight: bold;
			}
		}
		flex: 1;
	}
	.printer-fangroup {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px;
		height: 0;
		margin-bottom: -6mm;

		p {
			font-size: 8px;
			color: #000000;
			margin: 0;
			margin-right: 4px;
		}
		img {
			width: auto;
			height: 5mm;
		}
	}
}