.Help-btn {
  right: 0;
  bottom: 50%;
  z-index: 111;
  position: fixed;
  width: 43px;
  height: 50px;
  cursor: pointer;
  border-radius: 10%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border: none;
}
.Help-btn-refund {
  cursor: pointer;
  border-radius: 10%;
  background-color: rgb(255, 255, 255);
  border: none;
  padding-bottom: 5px;
}

@media print {
  .Help-btn {
    display: none;
  }
}

.Help-box {
  position: absolute;
  top: 55px;
  z-index: 102;
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.Help-Box-Content img{
  max-width: 100%;
  width: auto;
}

.Help-Modal{
  max-width: 80vw;
  max-height: 90vh;
}

.help-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 25px 25px 10px 10px;
  flex-wrap: wrap;

  h1 {
    font-size: 44px;
    font-weight: bold;
    color: #000000;
  }

  em {
    color: #474747;
    margin-bottom: 20px;
  }

  ul li {
    color: #474747;
    font-size: 18px;
  }

  btn-link.btn.btn-no-pad {
    margin-top: -3px;
    font-size: 18px;
  }

  h3:first-child {
    margin-top: 0;
  }

  h3 {
    margin-top: 30px;
    font-size: 24px;
    color: #000000;
    font-weight: bold;
  }
  img {
    width: auto;
    margin: 20px 0;
    margin-bottom: 20px;
  }
  p {
    color: #474747;
    font-size: 18px;
  }
}

.helper-ul {
  list-style: none;
}
.helper-ul-container {
  margin: 25px 0 0 10px;
  overflow-y: auto;
  max-height: 90%;
  height: 90vh;
}

.h-container{
  height: 90vh;
  width: 100%;
  overflow-y: auto;
}

.image-text{
  display: flex;
  align-items: flex-start;
}
.image-text-side{
  margin-left: 15px;
}
.image-image{
  margin: 15px;
}

.btn-no-pad {
  padding: 0;
  margin: 0;
  pointer-events: initial;
  &:before {
    background-color: white !important;
  }
  &::selection {
    text-decoration-line: underline;
    background: white;
  };
  &:hover {
    text-decoration-line: underline;
    background-color: white;
  };
  &:focus {
    text-decoration-line: underline;
  };
}

.help-pages {
  width: 215px;
  margin-right: 35px;
  min-width: 215px;
}

.help-active {
  font-weight: bold;
  color: #4A90E2;
}

.chapters {
  font-size: 1em;
  border-radius: 0%;
  border-style: none;
  background-color: #ffffff;
  padding: 7px 0;
}

.help-bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: #C9C9C9;
}

.help-headers {
  display: flex;
  align-items: center;
}

.help-bullet.active {
  width: 8px;
  height: 8px;
  background-color: #4A90E2;
}
