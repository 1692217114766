.pagination {

}


.pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;
  margin-right: 2px;
  border: 1px solid $color-border;
  background-color: white;
  border-radius: 6px !important;
  width: 28px;
  height: 28px;

  &:hover {
    color: $color-primary;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.pagination__link--arrow {

    .disabled {
      background-color: $color-disabled !important;
      border: 1px solid white;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.08) ;

      svg {
        fill: white;
      }
    }
  }
}

.pagination__item {

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;

    @include themify($themes) {
      color: $color-primary;
    }
  }

  &.disabled {

    .pagination__link svg {

      @include themify($themes) {
        fill: themed('colorIcon');
      }
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-additional;
}

.pagination__wrap {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
  p {
    margin: 0;
    margin-left: 10px;
  }

  select {
    padding: 5px;
    border-radius: 6px;
    border: 1px solid $color-border;
    color: $color-text;
  }
}

.pagination-info {
  color: $color-additional;
  font-size: 12px;
  margin: auto 0 auto 10px;
}