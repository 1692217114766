.app-preview-html {
    padding: 0 10px 0 10px;
    color: #646777;
    font-size: 16px;
    
    h1, h2, h3 {
        font-weight: bold;
        color: #212529;
    }

    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 18px;
    }
    
    p, span, strong, i, em, u {
        color: #646777;
    }
    blockquote {
        border-left: 4px solid #ccc;

    }
}