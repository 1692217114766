.table {

  &:last-child {
    margin-bottom: 0;
  }

  tbody td, th {
    border: none;
    padding: 10px;
    vertical-align: middle;

    @include themify($themes) {
      color: themed('colorTextAdditional');
    }
  }

  thead th {

    @include themify($themes) {
      color: themed('colorText');
      border-bottom-color: themed('colorBorder');
    }
  }

  .badge {
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: lighten($color-success, 50%);
      color: $color-success;
    }

    &.badge-secondary {
      background-color: lighten($color-secondary, 30%);
      color: $color-secondary;
    }

    &.badge-warning {
      background-color: lighten($color-warning, 30%);
      color: $color-warning;
    }

    &.badge-primary {
      background-color: lighten($color-primary, 50%);
      color: $color-primary;
    }

    &.badge-danger {
      background-color: lighten($color-danger, 30%);
      color: $color-danger;
    }

    &.badge-info {
      background-color: lighten($color-info, 20%);
      color: $color-info;
    }

    &.badge-premium {
      background-color: lighten($color-premium, 30%);
      color: $color-premium;
    }

    &.badge-disabled {
      background-color: lighten(#dddddd, 50%);
      color: #dddddd;
    }
  }

  &.table-bordered {

    tr {

      @include themify($themes) {
        border-bottom: 1px solid themed('tableBorder');
      }

      &:last-child {
        border-bottom: none;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &.table--head-accent {

    thead {

      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: themed('colorHover');
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {

    tbody tr:nth-of-type(odd) {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {

        @include themify($themes) {
          background-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  .company__profile__avatar_placeholder {
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    overflow: hidden;
    border-radius: 99em;
    bottom: 20px;
    box-shadow: 0 -2px 0 #fff;
    background-color: $color-primary;
    color: white;
    font-size: 14px;
    font-weight: 800;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .btn-group.btn-group-sm {
    margin-bottom: 0px;
  }

  .btn-group-sm {
    .btn {
      margin: 0px;
      padding: 3px 4px 5px 9px;
    }
  }
  
}
