.tickets {
	.container__wrap {
		display: flex;
		flex-direction: column;
		flex: 1;

		.container {
			display: flex;
			flex-direction: column;
			flex: 1;

			.tab-content {
				display: flex;
				flex-direction: column;
				flex: 1;

				.tab-pane.active {
					display: flex;
					flex-direction: column;
					flex: 1;

					.row {
						display: flex;
						flex-direction: column;
					}

					.card {
						display: flex;
						flex-direction: column;
						flex: 1;

						.card-body {
							overflow: hidden;

							.ReactVirtualized__Grid__innerScrollContainer {
								margin-bottom: 30px;
							}
						}

						.card__title {
							margin: 0;
						}
					}
				}
			}
		}
	}
}