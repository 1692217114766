.form-box {
    background: rgba(244, 244, 246, 1);
    border-radius: 4px;
    border: 1px solid rgba(215, 218, 224, 1);

    .form-box-title {
        padding: 5px;
        margin-bottom: 10px;
        border-bottom: 1px dashed rgba(215, 218, 224, 1);
    }
}

