.comCol {
    min-height: 312px;
    border-radius: 3%;
    flex-direction: column;
    font-weight: medium;
  }
  .templateImg {
    width: 250px;
    border-radius: 2%;
    flex-direction: column;
    object-fit: contain;
    margin-top: 42px;
    margin-right: 16px;
    &.grayBg{
      background-color: lightgray;
      height: 312px;
    }
  }
  .app-t {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .layoutRows {
    justify-content: center;
  }
  .rowDistance {
    padding-top: 20px;
  }
  .tempGroup {
    background: white;
    margin: 0 8px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    margin-bottom: 5px;
    justify-content: center;
    margin-top: 5px;
  }

  .tempGroupItem {
    :first-of-type {
      margin-top: 5px;
    }
    display: flex;
    justify-content: flex-start;
    padding: 5px 5px;
    margin: 2.5px 5px;
  }
  .tempGroupItemIconText {
    display: flex;
    flex: 2;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
    p {
      font-weight: bolder;
      font-size: 8px;
    }
    span {
      align-self: baseline;
      margin-right: 15px;
      font-size: 16px;
    }
  }
.tempGroupItemRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  span {
    font-size: 12px;
    font-weight: bolder;
    color: rgba(0,0,0,0.4)
  }
}

  .templateApp {
    width: 250px;
    height: 497px;
    overflow-y: scroll;
    background: #EEEEEE;
    img {
      margin-bottom: 5px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #313131;
    img {
      margin-top: 5px;
      width: 150px;
      height: auto;
    }
  }
  .templateTab {
    display: flex;
    height: 45px;
  }
  .temp1 {
    margin-right: 0px;
  }
  .templateTabElement {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    p, span {
      color: rgba(0,0,0,0.4);
    }
  }
  .first {
    p, span {
      color: black;
    }
  }
  .t-text {
    font-size: 5px;
    margin-top: -1px;
  }
  .fontSize-20 {
    font-size: 16px;
  }
  .templateTabElement:first-of-type {
    color: black;
    border-top: 3px black solid;
    span {
      color: rgb(0,0,0);
    }
    p {
      color: rgb(0,0,0);
    }
  }
