.row div span.field-large {
	background-color: #f6e000;
	color: black;
	border-radius: 5px;
	padding: 0 7px;
}

.row div span.field-normal {
	background-color: #145583;
	color: white;
	border-radius: 5px;
	padding: 0 7px;
}

.row div span.field-vip {
	background-color: #d9534f;
	color: white;
	border-radius: 5px;
	padding: 0 7px;
}

.row div span.field-unmarked {
	background-color: #d8d8d8;
	color: black;
	border-radius: 5px;
	padding: 0 7px;
}

.prices-box-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 32px;
	background-color: #e7e7e7;
}