.switch-btn {
  display: flex;
  align-items: center;
  height: 100%;

  &--label {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    color: $color-danger;

    &.active {
      color: $color-primary-hover;
    }
  }

  &--input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: $color-danger;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    margin: 0;

    &.active {
      background-color: $color-primary;
    }
  }

  &--container &--button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
  }

  &--input:checked + &--button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  &--container:active &--button {
    width: 24px;
  }
}
