.badge {

    &.bg-success {
      background-color: lighten($color-success, 50%) !important;
      color: $color-success;
    }

    &.bg-secondary {
      background-color: lighten($color-secondary, 30%) !important;
      color: $color-secondary;
    }

    &.bg-warning {
      background-color: lighten($color-warning, 30%) !important;
      color: $color-warning;
    }

    &.bg-primary {
      background-color: lighten($color-primary, 50%) !important;
      color: $color-primary;
    }

    &.bg-danger {
      background-color: lighten($color-danger, 30%) !important;
      color: $color-danger;
    }

    &.bg-info {
      background-color: lighten($color-info, 20%) !important;
      color: $color-info;
    }

    &.bg-premium {
      background-color: lighten($color-premium, 30%) !important;
      color: $color-premium;
    }

    &.bg-disabled {
      background-color: lighten(#dddddd, 50%) !important;
      color: #dddddd;
    }
  }